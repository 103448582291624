import { FaAndroid, FaDownload, FaLinkedinIn, FaWindows } from "react-icons/fa";

const Home = () => {
  return (
    <section className="relative min-h-screen">
      {/* Degrade */}
      <div className="absolute h-full w-full">
        <img
          src="hero.svg"
          className="h-[600px] w-full bg-contain"
          width={100}
          height={100}
          alt="FollowIt"
        />
      </div>

      {/* Content */}
      <div className="absolute left-1/2 -translate-x-1/2 w-7/12 pb-10 z-10">
        <div className="mt-24 text-center">
          <h1 className="text-5xl text-white uppercase">FollowIt</h1>
          <h3 className="mt-4 text-xl text-gray-200">
            Seguidor de negocios y temas profesionales/laborales
          </h3>
        </div>

        <div
          className="mt-12 flex flex-col md:flex-row justify-center space-y-4 md:space-y-0 md:space-x-12 md:items-center"
          id="apps"
        >
          <div className="flex justify-center">
            <div className="rounded-lg shadow-lg bg-white max-w-sm">
              <a href="#!" className="p-2">
                <img
                  className="rounded-t-lg mx-auto h-48 w-auto bg-contain"
                  src="/A1.png"
                  alt="Profesional"
                />
              </a>
              <div className="p-6">
                <h5 className="text-gray-900 text-2xl font-medium mb-2">
                  Profesional
                </h5>
                <p className="text-gray-700 text-base mb-4">
                  Dedicado especialmente al seguimiento de temas profesionales
                  de larga duraci&oacute;n.
                </p>
                <div className="flex flex-row space-x-2">
                  <a
                    href="/followit_professional.apk"
                    download={true}
                    className="inline-block px-6 py-2.5 bg-green-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-green-700 hover:shadow-lg focus:bg-green-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-green-800 active:shadow-lg transition duration-150 ease-in-out"
                  >
                    <FaAndroid size={32} />
                  </a>
                  <a
                    href="/followit_professional.zip"
                    download={true}
                    className="inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
                  >
                    <FaWindows size={32} />
                  </a>
                  {/* <a
                    href="/"
                    download={true}
                    className="inline-block px-6 py-2.5 bg-gray-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-gray-700 hover:shadow-lg focus:bg-gray-700 focus:shadow-lg focus:outline-none focus:ring-0 active:gray-blue-800 active:shadow-lg transition duration-150 ease-in-out"
                  >
                    <FaApple size={32} />
                  </a> */}
                </div>
              </div>
            </div>
          </div>
          {/* <div className="flex justify-center">
            <div className="rounded-lg shadow-lg bg-white max-w-sm">
              <a href="#!" className="p-2">
                <img
                  className="rounded-t-lg mx-auto h-48 w-auto bg-contain"
                  src="/b1.png"
                  alt="Profesional"
                />
              </a>
              <div className="p-6">
                <h5 className="text-gray-900 text-2xl font-medium mb-2">
                  Gerentes
                </h5>
                <p className="text-gray-700 text-base mb-4">
                  Presentado para el seguimiento de procesos, tareas propias y
                  de sus colaboradores
                </p>
                <div className="flex flex-row space-x-2">
                  <a
                    href="/followit_manager.apk"
                    download={true}
                    className="inline-block px-6 py-2.5 bg-green-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-green-700 hover:shadow-lg focus:bg-green-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-green-800 active:shadow-lg transition duration-150 ease-in-out"
                  >
                    <FaAndroid size={32} />
                  </a>
                  <a
                    href="/followit_manager.exe"
                    download={true}
                    className="inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
                  >
                    <FaWindows size={32} />
                  </a>
                  <a
                    href="/"
                    download={true}
                    className="inline-block px-6 py-2.5 bg-gray-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-gray-700 hover:shadow-lg focus:bg-gray-700 focus:shadow-lg focus:outline-none focus:ring-0 active:gray-blue-800 active:shadow-lg transition duration-150 ease-in-out"
                  >
                    <FaApple size={32} />
                  </a>
                </div>
              </div>
            </div>
          </div> */}
          {/* <div className="flex justify-center">
            <div className="rounded-lg shadow-lg bg-white max-w-sm">
              <a href="#!" className="p-2">
                <img
                  className="rounded-t-lg mx-auto h-48 w-auto bg-contain"
                  src="/FollowIt_symbol.png"
                  alt="Negocios"
                />
              </a>
              <div className="p-6">
                <h5 className="text-gray-900 text-2xl font-medium mb-2">
                  Negocios
                </h5>
                <p className="text-gray-700 text-base mb-4">
                  Especialmente dise&ntilde;ado para el seguimiento de negocios
                  incrementando sus ventas
                </p>
                <div className="flex flex-row space-x-2">
                  <a
                    href="/followit_sales.apk"
                    download={true}
                    className="inline-block px-6 py-2.5 bg-green-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-green-700 hover:shadow-lg focus:bg-green-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-green-800 active:shadow-lg transition duration-150 ease-in-out"
                  >
                    <FaAndroid size={32} />
                  </a>
                  <a
                    href="/followit_sales.zip"
                    download={true}
                    className="inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
                  >
                    <FaWindows size={32} />
                  </a>
                  <a
                    href="/"
                    download={true}
                    className="inline-block px-6 py-2.5 bg-gray-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-gray-700 hover:shadow-lg focus:bg-gray-700 focus:shadow-lg focus:outline-none focus:ring-0 active:gray-blue-800 active:shadow-lg transition duration-150 ease-in-out"
                  >
                    <FaApple size={32} />
                  </a>
                </div>
              </div>
            </div>
          </div> */}
        </div>

        <div className="mt-12 p-4 flex flex-col md:flex-row items-center justify-center space-y-2 md:space-y-0 md:space-x-4">
          <div className="md:w-1/2 flex flex-col space-y-4 text-slate-500 text-xl">
            <p className="text-justify">
              Una app multi-plataforma que te permite conocer y recordad el
              estado de tus temas pasados, pendientes y futuros
            </p>
            <p className="text-justify">
              Desarrollado con una tecnolog&iacute;a innovadora para poder
              utilizarla desde el tel&eacute;fono inteligente hasta una PC de
              escritorio
            </p>
          </div>
          <div className="md:w-1/2">
            <img
              src="/pc_cell.jpg"
              className="h-auto w-full rounded-xl"
              alt="App presentada en el celular y una notebook"
            />
          </div>
        </div>

        <div className="mt-12 p-4 flex flex-col md:flex-row items-center justify-center space-y-2 md:space-y-0 md:space-x-4">
          <div className="md:w-1/2">
            <img
              src="/actions.png"
              className="h-96 mx-auto w-auto rounded-xl"
              alt="App presentada en el celular y una notebook"
            />
          </div>

          <div className="md:w-1/2 flex flex-col space-y-4 text-slate-500 text-xl">
            <p className="text-justify">
              En la vit&aacute;cora puedes programar notificaciones para tus
              acciones de una forma s&uacute;per sencilla
            </p>
          </div>
        </div>

        <div className="mt-12 p-4 flex flex-col md:flex-row items-center justify-center space-y-2 md:space-y-0 md:space-x-4">
          <div className="md:w-1/2 flex flex-col space-y-4 text-slate-500 text-xl">
            <p className="text-justify">
              Agrega los contactos de los actores principales as&iacute; como
              archivos PDF, office, audios, videos, etc.
            </p>
          </div>

          <div className="md:w-1/2">
            <img
              src="/businessView.png"
              className="h-96 mx-auto w-auto rounded-xl"
              alt="App presentada en el celular y una notebook"
            />
          </div>
        </div>

        <div className="mt-12 w-1/2 mx-auto">
          <h3 className="text-3xl text-gray-700 text-center font-bold">Guía Básica de Uso</h3>
          <div class="mt-8 aspect-w-16 aspect-h-9">
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/qFwWe-IKT5M"
              title="Guía Básica de Uso"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
          </div>
        </div>

        <div className="mt-12 px-10 py-20 flex flex-col md:flex-row items-center justify-between space-y-12 md:space-y-0 rounded-md bg-gr1 text-white">
          <h2 className="text-3xl font-bold text-center md:text-left">
            ¿Est&aacute;s listo para mejorar tus negocios?
          </h2>

          <a href="#apps">
            <FaDownload size={32} />
          </a>
        </div>

        <div className="mt-12 flex flex-row items-center justify-between">
          <p className="text-gray-400">
            &copy; 2022 Arcanix, all rights reserved
          </p>

          <div className="flex flex-row items-center space-x-4 text-cyan-500">
            <a
              href="https://www.linkedin.com/company/arcanix-uy/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaLinkedinIn size={16} />
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Home;
